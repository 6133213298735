import React from "react";

interface PriceCardProps {
  pair: string;
  price: any;
  color: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const PairCard: React.FC<PriceCardProps> = ({
  pair,
  price,
  color,
  style,
  onClick,
}) => {
  const cardStyle: React.CSSProperties = {
    display: "inline-block",
    padding: "6px",
    margin: 0,
    // border: "1px solid rgb(27 114 165)",
    borderRadius: "6px",
    backgroundColor: "rgb(255 255 255 / 5%)",
    minWidth: "100px",
    textAlign: "center",
    ...style, // Ek stil objesini buraya uyguluyoruz
  };

  const pairStyle: React.CSSProperties = {
    fontSize: "13px",
    // color: color,
    // fontWeight: "bold",
  };

  const priceStyle: React.CSSProperties = {
    fontSize: "16px",
    fontWeight: "bold",
  };

  if (price == 0) return <div></div>;

  return (
    <div style={cardStyle} onClick={onClick}>
      <div className="gx-mb-1" style={pairStyle}>
        {pair}
      </div>
      <div className={color} style={priceStyle}>
        {price}
      </div>
    </div>
  );
};

export default PairCard;
