import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { FirebaseFieldValue, storage } from "../../../firebase";
import { message } from "antd";

const COLLECTION_NAME = "BRO_CHAT";

export interface IChat {
  sentAt: string;
  type: string;
  date: any;
  message: string;
}

interface IChatList {
  list: IChat[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IChatList = {
  list: [],
  requested: false,
  loaded: false,
};

export async function addNewMessage(firebase: any, docId: string, message: string) {
  try {
    const docRef = firebase.firestore().collection(COLLECTION_NAME).doc(docId);

    await docRef.update({
      chats: FirebaseFieldValue.arrayUnion({
        type: "sent",
        date: moment().toDate(),
        message: message,
      })
    });
  } catch (error) {
    console.log(error)
  }
}

export function loadChat(firebase: any, docId: string) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Chat.requested) return;

    dispatch({
      type: SENTIMENT_ACTION.CHAT,
      payload: { requested: true, loaded: false, list: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .doc(docId)
      .onSnapshot((doc: any) => {
        if (doc.exists) {
          const item = doc.data();

          var data: IChat[] = [];
          item?.chats.forEach((item: any) => {
            data.push({
              sentAt: moment.unix(item.date.seconds).format("HH:mm"),
              date: moment.unix(item.date.seconds),
              type: item.type ? "sent" : "received",
              message: item.message,
            });
          });

          dispatch({
            type: SENTIMENT_ACTION.CHAT,
            payload: {
              list: data,
              loaded: true,
              requested: true,
            },
          });
        } else {
          dispatch({
            type: SENTIMENT_ACTION.CHAT,
            payload: {
              list: [],
              loaded: true,
              requested: true,
            },
          });
        }
      })
      .catch((error: any) => {
        dispatch({
          type: SENTIMENT_ACTION.CHAT,
          payload: {
            list: [],
            loaded: false,
            requested: true,
          },
        });
      });
  };
}

export default function Chat(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.CHAT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export const chatSelector = (state: any) => state.Chat;
