import { useEffect } from "react";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import { getDateStr } from "../../routes/Web/Messages/helpers";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../NextApp";
import {
  loadMessages,
  messagesSelector,
} from "../../appRedux/reducers/web/messages";
import { firebaseApp } from "../../firebase";
import { Avatar } from "antd";
import { Link } from "react-router-dom";

const MessagesPopover = () => {
  const dispatch = useAppDispatch();
  const { themeType } = useSelector(({ settings }) => settings);

  useEffect(() => {
    dispatch(loadMessages(firebaseApp));
  }, [dispatch]);

  const messages = useSelector(messagesSelector)?.list;

  if (!messages) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <Auxiliary>
      <div className="gx-popover-header">
        <h3 className="gx-mb-0">Mesajlar</h3>
        <Link to="/chat">
          <h6>
            Tüm Mesajları Gör{" "}
            <img
              src={
                themeType === "THEME_TYPE_DARK"
                  ? "/assets/images/click-white.png"
                  : "/assets/images/click-black.png"
              }
              alt="help-click"
              height="14px"
              width="14px"
            />
          </h6>
        </Link>
      </div>
      <CustomScrollbars
        style={{
          height: 280,
          width: 300,
          position: "relative",
          overflow: "hidden",
          margin: "0 -16px",
        }}
      >
        {!messages ? (
          <div>Yükleniyor</div>
        ) : (
          <ul className="gx-sub-popover">
            {messages.slice(0, 3).map((message, index) => (
              <li className="gx-media" key={index}>
                <div className="gx-user-thumb gx-mr-3">
                  <Avatar
                    className="gx-size-30"
                    alt="sentiment-algo"
                    src="/assets/images/mini-logo.png"
                  />
                </div>
                <div className="gx-media-body">
                  <div className="gx-flex-row gx-justify-content-between gx-align-items-center">
                    <h5 className="gx-text-capitalize gx-user-name gx-mb-0">
                      <span className="gx-link">
                        {" "}
                        {getDateStr(message.date)}
                      </span>
                    </h5>
                    <span className="gx-meta-date">
                      <small></small>
                    </span>
                  </div>
                  <p className="gx-fs-sm ">{message.text}</p>
                </div>
              </li>
            ))}
          </ul>
        )}
      </CustomScrollbars>
    </Auxiliary>
  );
};

export default MessagesPopover;
