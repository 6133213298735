import { useEffect, useState } from "react";
import { Row, Drawer, Collapse, Select, Grid } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import { onLayoutTypeChange, setThemeType } from "appRedux/actions/Setting";
import { Link } from "react-router-dom";
import {
  ACTIVE_COLOR_OPTION,
  BLUE,
  BLUE_SEC,
  DARK_BLUE,
  DARK_BLUE_SEC,
  DEEP_ORANGE,
  DEEP_ORANGE_SEC,
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  LIGHT_BLUE,
  LIGHT_BLUE_SEC,
  LIGHT_PURPLE,
  LIGHT_PURPLE_1,
  LIGHT_PURPLE_1_SEC,
  LIGHT_PURPLE_2,
  LIGHT_PURPLE_2_SEC,
  LIGHT_PURPLE_SEC,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  ORANGE,
  ORANGE_SEC,
  RED,
  RED_SEC,
} from "../constants/ThemeSetting";
import {
  onNavStyleChange,
  setDirectionRTL,
  setThemeColor,
  switchLanguage,
} from "../appRedux/actions";
import { HELP_MENU } from "../constants/AppUsage";
import IntlMessages from "../util/IntlMessages";
import { useIntl } from "react-intl";
import AppLocale from "../lngProvider";
import { nsUserSelector } from "../appRedux/reducers/sentiment/user";

const Panel = Collapse.Panel;
const { useBreakpoint } = Grid;

const CustomizerSystem = (props) => {
  const intl = useIntl();

  const [isCustomizerOpened, setIsCustomizerOpened] = useState(false);
  useEffect(() => {
    setIsCustomizerOpened(props.showSettings);
  }, [props.showSettings]);
  const { themeType, themeColor, navStyle, layoutType, isDirectionRTL } =
    useSelector(({ settings }) => settings);
  const dispatch = useDispatch();

  const handleLangChange = (lang) => {
    localStorage.setItem("lang", lang);
    localStorage.setItem("isRTL", lang === "ar");
    if (lang === "ar") dispatch(setDirectionRTL(true));
    else dispatch(setDirectionRTL(false));

    dispatch(switchLanguage(AppLocale[lang]));
  };

  const nsUser = useSelector(nsUserSelector);

  const handleKlineIntervalChange = (interval) => {
    localStorage.setItem("brokerage_kint", interval);
    window.location.reload(false);
  };

  const handleColorChange = (filename) => {
    dispatch(setThemeColor(filename));
  };

  const toggleCustomizer = () => {
    props.setShowsSettings(!isCustomizerOpened);
  };

  const onThemeTypeChange = (e) => {
    dispatch(setThemeType(e.target.value));
  };

  const onNavStyleChanges = (navStyle) => {
    dispatch(onNavStyleChange(navStyle));
  };

  const getKlineTextColor = () => {
    var klineInterval = localStorage.getItem("brokerage_kint");
    if (klineInterval == "1W") return "gx-text-white";
    if (klineInterval == "4H") return " gx-text-orange";
    if (klineInterval == "4H_v2") return " gx-text-pink";
    return "hidden";
  };

  const screens = useBreakpoint();

  const getCustomizerContent = () => {
    return (
      <CustomScrollbars
        className="gx-customizer"
        style={{ width: screens.xs ? 280 : "" }}
      >
        <div className="gx-customizer-item">
          <Row
            justify="space-between"
            align="middle"
            style={{ margin: 0, height: 50 }}
          >
            {screens.xs ? (
              <h6
                className="gx-mb-3 gx-text-uppercase"
                style={{ marginTop: 10, fontWeight: "bold" }}
              >
                <IntlMessages id="sidebar.help" />
              </h6>
            ) : (
              <h4
                className="gx-mb-3 gx-text-uppercase"
                style={{ fontWeight: "bold" }}
              >
                <IntlMessages id="sidebar.help" />
              </h4>
            )}

            <Link to="/help" onClick={() => toggleCustomizer(false)}>
              <h6 style={{ marginTop: screens.xs ? "" : -4 }}>
                Daha Fazla Destek{" "}
                <img
                  src={
                    themeType === "THEME_TYPE_DARK"
                      ? "/assets/images/click-white.png"
                      : "/assets/images/click-black.png"
                  }
                  alt="help-click"
                  height="14px"
                  width="14px"
                />
              </h6>{" "}
            </Link>
          </Row>
          <Collapse accordion defaultActiveKey={["0"]}>
            {HELP_MENU.map((item, i) => (
              <Panel
                header={
                  item.key ? intl.formatMessage({ id: item.key }) : item.title
                }
                key={i}
              >
                <p>
                  {item.descKey
                    ? intl.formatMessage({ id: item.descKey })
                    : item.desc}
                </p>
              </Panel>
            ))}
          </Collapse>
        </div>
      </CustomScrollbars>
    );
  };

  function onChangeDirection(checked) {
    dispatch(setDirectionRTL(checked));
  }

  const handleThemeColor = (fileName) => {
    handleColorChange(fileName);
  };

  const handleLayoutTypes = (layoutType) => {
    dispatch(onLayoutTypeChange(layoutType));
  };

  const getPresetColors = (themeColor) => {
    return (
      <ul className="gx-color-option gx-list-inline">
        <li>
          <span
            onClick={() => handleThemeColor("light_purple")}
            style={{
              backgroundColor: LIGHT_PURPLE_SEC,
              color: ACTIVE_COLOR_OPTION,
            }}
            className={`gx-link gx-color-light-purple ${
              themeColor === LIGHT_PURPLE && "active"
            }`}
          />
        </li>
        <li>
          <span
            onClick={() => handleThemeColor("red")}
            style={{ backgroundColor: RED_SEC, color: ACTIVE_COLOR_OPTION }}
            className={`gx-link gx-color-red ${themeColor === RED && "active"}`}
          />
        </li>
        <li>
          <span
            onClick={() => handleThemeColor("blue")}
            style={{ backgroundColor: BLUE_SEC, color: ACTIVE_COLOR_OPTION }}
            className={`gx-link gx-color-blue ${
              themeColor === BLUE && "active"
            }`}
          />
        </li>
        <li>
          <span
            onClick={() => handleThemeColor("dark_blue")}
            style={{
              backgroundColor: DARK_BLUE_SEC,
              color: ACTIVE_COLOR_OPTION,
            }}
            className={`gx-link gx-color-dark-blue ${
              themeColor === DARK_BLUE && "active"
            }`}
          />
        </li>

        <li>
          <span
            onClick={() => handleThemeColor("orange")}
            style={{ backgroundColor: ORANGE_SEC, color: ACTIVE_COLOR_OPTION }}
            className={`gx-link gx-color-orange ${
              themeColor === ORANGE && "active"
            }`}
          />
        </li>

        <li>
          <span
            onClick={() => handleThemeColor("light_blue")}
            style={{
              backgroundColor: LIGHT_BLUE_SEC,
              color: ACTIVE_COLOR_OPTION,
            }}
            className={`gx-link gx-color-light-blue ${
              themeColor === LIGHT_BLUE && "active"
            }`}
          />
        </li>

        <li>
          <span
            onClick={() => handleThemeColor("deep_orange")}
            style={{
              backgroundColor: DEEP_ORANGE_SEC,
              color: ACTIVE_COLOR_OPTION,
            }}
            className={`gx-link gx-color-deep-orange ${
              themeColor === DEEP_ORANGE && "active"
            }`}
          />
        </li>

        <li>
          <span
            onClick={() => handleThemeColor("light_purple_1")}
            style={{
              backgroundColor: LIGHT_PURPLE_1_SEC,
              color: ACTIVE_COLOR_OPTION,
            }}
            className={`gx-link gx-color-light-purple1 ${
              themeColor === LIGHT_PURPLE_1 && "active"
            }`}
          />
        </li>

        <li>
          <span
            onClick={() => handleThemeColor("light_purple_2")}
            style={{
              backgroundColor: LIGHT_PURPLE_2_SEC,
              color: ACTIVE_COLOR_OPTION,
            }}
            className={`gx-link gx-color-light-purple2 ${
              themeColor === LIGHT_PURPLE_2 && "active"
            }`}
          />
        </li>
      </ul>
    );
  };

  const getLayoutsTypes = (layoutType) => {
    return (
      <ul className="gx-layout-option gx-list-inline">
        <li>
          <span
            onClick={() => handleLayoutTypes(LAYOUT_TYPE_FRAMED)}
            className={`gx-pointer ${
              layoutType === LAYOUT_TYPE_FRAMED && "active"
            }`}
          >
            <img src={"/assets/images/layouts/framed.png"} alt="framed" />
          </span>
        </li>
        <li>
          <span
            onClick={() => handleLayoutTypes(LAYOUT_TYPE_FULL)}
            className={`gx-pointer ${
              layoutType === LAYOUT_TYPE_FULL && "active"
            }`}
          >
            <img
              src={"/assets/images/layouts/full width.png"}
              alt="full width"
            />
          </span>
        </li>
        <li>
          <span
            onClick={() => handleLayoutTypes(LAYOUT_TYPE_BOXED)}
            className={`gx-pointer ${
              layoutType === LAYOUT_TYPE_BOXED && "active"
            }`}
          >
            <img src={"/assets/images/layouts/boxed.png"} alt="boxed" />
          </span>
        </li>
      </ul>
    );
  };

  const getNavStyles = (navStyle) => {
    return (
      <ul className="gx-nav-option gx-list-inline">
        <li>
          <span
            onClick={() => onNavStyleChanges(NAV_STYLE_FIXED)}
            className={`gx-pointer ${navStyle === NAV_STYLE_FIXED && "active"}`}
          >
            <img src={"/assets/images/layouts/fixed.png"} alt="fixed" />
          </span>
        </li>
        <li>
          <span
            onClick={() => onNavStyleChanges(NAV_STYLE_MINI_SIDEBAR)}
            className={`gx-pointer ${
              navStyle === NAV_STYLE_MINI_SIDEBAR && "active"
            }`}
          >
            <img
              src={"/assets/images/layouts/mini sidebar.png"}
              alt="mini sidebar"
            />
          </span>
        </li>
        <li>
          <span
            onClick={() => onNavStyleChanges(NAV_STYLE_DRAWER)}
            className={`gx-pointer ${
              navStyle === NAV_STYLE_DRAWER && "active"
            }`}
          >
            <img
              src={"/assets/images/layouts/drawer nav.png"}
              alt="drawer nav"
            />
          </span>
        </li>
        <li>
          <span
            onClick={() => onNavStyleChanges(NAV_STYLE_NO_HEADER_MINI_SIDEBAR)}
            className={`gx-pointer ${
              navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && "active"
            }`}
          >
            <img
              src={"/assets/images/layouts/no header mini sidebar.png"}
              alt="no hader mini sidebar"
            />
          </span>
        </li>
        <li>
          <span
            onClick={() =>
              onNavStyleChanges(NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR)
            }
            className={`gx-pointer ${
              navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR && "active"
            }`}
          >
            <img
              src={"/assets/images/layouts/vertical no header.png"}
              alt="vertical no header"
            />
          </span>
        </li>
        <li>
          <span
            onClick={() => onNavStyleChanges(NAV_STYLE_DEFAULT_HORIZONTAL)}
            className={`gx-pointer ${
              navStyle === NAV_STYLE_DEFAULT_HORIZONTAL && "active"
            }`}
          >
            <img
              src={"/assets/images/layouts/default horizontal.png"}
              alt="default horizontal"
            />
          </span>
        </li>
        <li>
          <span
            onClick={() => onNavStyleChanges(NAV_STYLE_DARK_HORIZONTAL)}
            className={`gx-pointer ${
              navStyle === NAV_STYLE_DARK_HORIZONTAL && "active"
            }`}
          >
            <img
              src={"/assets/images/layouts/dark horizontal.png"}
              alt="dark horizontal"
            />
          </span>
        </li>
        <li>
          <span
            onClick={() =>
              onNavStyleChanges(NAV_STYLE_INSIDE_HEADER_HORIZONTAL)
            }
            className={`gx-pointer ${
              navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL && "active"
            }`}
          >
            <img
              src={"/assets/images/layouts/inside header horizontal.png"}
              alt="inside header horizontal"
            />
          </span>
        </li>
        <li>
          <span
            onClick={() => onNavStyleChanges(NAV_STYLE_BELOW_HEADER)}
            className={`gx-pointer ${
              navStyle === NAV_STYLE_BELOW_HEADER && "active"
            }`}
          >
            <img
              src={"/assets/images/layouts/below header.png"}
              alt="below header"
            />
          </span>
        </li>

        <li>
          <span
            onClick={() => onNavStyleChanges(NAV_STYLE_ABOVE_HEADER)}
            className={`gx-pointer ${
              navStyle === NAV_STYLE_ABOVE_HEADER && "active"
            }`}
          >
            <img
              src={"/assets/images/layouts/top to header.png"}
              alt="top to header"
            />
          </span>
        </li>
      </ul>
    );
  };

  return (
    <>
      <Drawer
        placement="right"
        closable={false}
        onClose={toggleCustomizer}
        open={isCustomizerOpened}
      >
        {getCustomizerContent()}
      </Drawer>
    </>
  );
};

export default CustomizerSystem;
