export const getPeriod = () => {
  let interval = localStorage ? localStorage.getItem("brokerage_kint") : "";
  return interval;
};

export const isWeekPeriod = () => {
  return localStorage.getItem("brokerage_kint") === "1W";
};

export const is240Period = () => {
  return localStorage.getItem("brokerage_kint") === "4H" || localStorage.getItem("brokerage_kint") === "4H_v2";
};

export const isDayPeriod = () => {
  return localStorage.getItem("brokerage_kint") === null || localStorage.getItem("brokerage_kint") === "";
};