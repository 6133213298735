import { Button, Form, Input, Row, Col, Typography, Grid } from "antd";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import "./login.css";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const SignIn = () => {
  const screens = useBreakpoint();
  const intl = useIntl();
  const { isLoading, error, userLogin, setAuthUser } = useAuth();
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    setLoading(true);
    setResult("");
    userLogin(values, (err) => {
      if (err && err.code) {
        switch (err.code) {
          case "auth/user-not-found":
          case "auth/wrong-password":
            setResult(
              intl.formatMessage({ id: "page.login.invalid_email_or_password" })
            );
            break;
          case "auth/operation-not-allowed":
            setResult("Kullanıcı hesabı aktif değil!");
            break;
          case "auth/user-disabled":
            setResult("Kullanıcı hesabı aktif değil(1)!");
            break;
          case "auth/operation-not-allowed":
            setResult("Kullanıcı hesabı aktif değil(2)!");
            break;
          case "auth/invalid-credential":
            setResult("Kullanıcı bilgileri hatalı!");
            break;
        }
      } else {
        setResult(err.message);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 60,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
        },
        size: {
          value: 4,
          random: true,
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 3,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: false,
            mode: "repulse",
          },
          onclick: {
            enable: false,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  }, []);

  return (
    <Row
      style={{
        margin: 0,
        height: "100vh",
        width: "100vw",
        backgroundImage: 'url("/assets/images/layouts/login-bg.png")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: 1,
      }}
    >
      <div
        id="particles-js"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 3,
        }}
      ></div>

      <Col xl={2} lg={1} md={2} sm={2} xs={0} />
      <Col
        xl={11}
        lg={13}
        md={20}
        sm={20}
        xs={24}
        style={{ height: "100%", width: "100%", zIndex: 4 }}
      >
        <Row
          align="middle"
          justify={screens.lg ? "end" : "center"}
          style={{ height: "100%", margin: 0 }}
        >
          <Col
            style={{
              background: "rgba(255, 255, 255, 0.1)",
              backdropFilter: "blur(10px)",
              boxShadow: "0px 7px 29px 0px rgba(255, 255, 255, 0.1)",
              width: screens.xs ? "100%" : screens.xl ? "80%" : "90%",
              height: screens.xl
                ? "70vh"
                : screens.lg
                ? "70vh"
                : screens.md
                ? "60vh"
                : screens.sm
                ? "60vh"
                : screens.xs
                ? "60vh"
                : "60vh",
              borderRadius: 16,
            }}
          >
            <Row
              justify="center"
              align="middle"
              style={{
                height: "100%",
                padding: 10,
                margin: 0,
                color: "white",
              }}
            >
              <div style={{ height: "fit-content", maxWidth: 500 }}>
                <Col span={24}>
                  <Title
                    level={screens.xs ? 2 : 1}
                    style={{
                      margin: 0,
                      padding: 0,
                      width: "100%",
                      color: "white",
                      fontSize: screens.xxl ? 50 : screens.xl ? 40 : "",
                    }}
                  >
                    Sentiment Algo
                  </Title>
                  <Title
                    level={4}
                    style={{
                      margin: 0,
                      padding: screens.xs ? "0 0 30px 0" : "0 0 40px 0",
                      width: "100%",
                      position: "relative",
                      color: "white",
                      fontSize: screens.xxl ? 32 : screens.xl ? 28 : "",
                    }}
                  >
                    Broker Panel
                    <img
                      src="/assets/images/layouts/blue-line.png"
                      style={{
                        position: "absolute",
                        width: screens.xxl ? 290 : screens.xl ? 270 : 200,
                        height: 40,
                        top: screens.xxl ? 20 : screens.xl ? 15 : 10,
                        left: screens.xxl ? -40 : screens.xl ? -46 : -30,
                      }}
                    />
                  </Title>
                  <Text
                    style={{
                      width: "100%",
                      color: "white",
                    }}
                  >
                    Sentiment Algo algoritmaları ile, kurumsal ve profesyonel
                    yatırımcılara özel olarak hazırlanan Broker Panel'e
                    hoşgeldiniz.
                  </Text>
                </Col>
                <Col span={24} style={{ paddingTop: 20 }}>
                  <Row
                    justify="center"
                    align="middle"
                    style={{
                      height: "100%",
                      padding: 10,
                      margin: 0,
                      color: "white",
                    }}
                  >
                    {result && result !== "" && (
                      <Col
                        span={24}
                        style={{
                          paddingLeft: 0,
                          paddingBottom: 24,
                          marginLeft: -24,
                        }}
                      >
                        <Text
                          style={{
                            color: "#FF4D4F",
                            textAlign: "left",
                            fontWeight: 500,
                          }}
                          role="alert"
                        >
                          {result}
                        </Text>
                      </Col>
                    )}
                    <Form
                      initialValues={{ remember: true }}
                      name="basic"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      style={{ width: "100%" }}
                      size="large"
                    >
                      <Form.Item
                        style={{ paddingBottom: 10 }}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: "page.login.invalid_email",
                            }),
                          },
                        ]}
                        name="email"
                      >
                        <Input
                          placeholder={intl.formatMessage({
                            id: "page.login.email",
                          })}
                          className="bp-login-input"
                          autoCapitalize="off"
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ paddingBottom: 10 }}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: "page.login.please_enter_your_password",
                            }),
                          },
                        ]}
                        name="password"
                      >
                        <Input
                          type="password"
                          placeholder={intl.formatMessage({
                            id: "page.login.password",
                          })}
                          className="bp-login-input"
                          autoCapitalize="off"
                        />
                      </Form.Item>
                      <Form.Item>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Button
                            loading={loading}
                            block
                            htmlType="submit"
                            size="large"
                            style={{ fontWeight: "bold" }}
                          >
                            <IntlMessages id="page.login.login" />
                          </Button>
                        </div>
                      </Form.Item>
                    </Form>
                    <AppNotificationContainer
                      loading={isLoading}
                      error={error}
                    />
                  </Row>
                </Col>
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SignIn;
