import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import { symbol } from "prop-types";

const DATA_COUNT = 100;
const COLLECTION_NAME = "NS_EXCHANGE_SIGNAL";

interface IExchangeSignalData {
  data: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IExchangeSignalData = {
  data: [],
  requested: false,
  loaded: false,
};

export function loadExchangeSignal(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {

    const state = getState();
    if (state.ExchangeSignal.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.EXCHANGE_SIGNAL,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("date", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          data.push({
            id: doc.data().id,
            symbol: doc.data().symbol,
            Symbol: doc.data().symbol,
            date: moment(doc.data().date.toDate()).format("DD.MM.YY HH:mm"),
            time: moment(doc.data().date.toDate()).format("HH:mm"),
            signal: doc.data().signal,
            text: doc.data().text,
            price: doc.data().price,
            direction: doc.data().direction,
            corporate: doc.data().corporate,
            percent: doc.data().percent,
            amount: doc.data().amount,
            netAmount: doc.data().netAmount,
          });

        });

        dispatch({
          type: SENTIMENT_ACTION.EXCHANGE_SIGNAL,
          payload: {
            data: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function ExchangeSignal(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.EXCHANGE_SIGNAL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const exchangeSignalSelector = (state: any) => state.ExchangeSignal;
