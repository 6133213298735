import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { auth } from "../../../firebase";
import { isProd } from "../../../util/Env";

const COLLECTION_NAME = "NS_CORPORATES_V2_USERS";
const PACKAGE_COLLECTION_NAME = "NS_PACKAGE";

interface IXUData {
  permissions: object;
  requested: boolean;
  loaded: boolean;
  needRefresh: boolean;
}

const initialState: IXUData = {
  permissions: {},
  requested: false,
  needRefresh: false,
  loaded: false,
};
export function refreshUser(firebase: any, userId: string) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    dispatch({
      type: SENTIMENT_ACTION.NS_USER,
      payload: {
        user: state.NsUser.user,
        agreement: state.NsUser.agreement,
        cooperate: state.NsUser.cooperate,
        loaded: false,
        requested: false,
        needRefresh: false,
      },
    });
  };
}
export function loadNsUser(firebase: any, userId: string) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (!userId) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
      return;
    }
    if (state.NsUser.requested && !state.NsUser.needRefresh) return;
    dispatch({
      type: SENTIMENT_ACTION.NS_USER,
      payload: {
        requested: true,
        loaded: false,
        sentiment: [],
        needRefresh: false,
      },
    });
    try {
      const userDoc = await firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(userId)
        .onSnapshot(
          async (doc: any) => {
            if (doc.metadata.fromCache) return;
            const userData = doc?.data();
            if(userData && !userData.active){
              auth.signOut();
              return;
            }
            console.log(userData)
            if (userData && userData["meta.package-id"]) {
              await firebase
                .firestore()
                .collection(PACKAGE_COLLECTION_NAME)
                .doc(userData["meta.package-id"].toString())
                .onSnapshot((userPackage: any) => {
                  const packageData = userPackage?.data();

                  dispatch({
                    type: SENTIMENT_ACTION.NS_USER,
                    payload: {
                      user: packageData,
                      package: userData,
                      agreement: userData["meta.agreement"],
                      cooperate: userData["meta.cooperate"],
                      admin: userData["admin"],
                      loaded: true,
                      requested: true,
                      needRefresh: false,
                    },
                  });
                });
            } else {
              dispatch({
                type: SENTIMENT_ACTION.NS_USER,
                payload: {
                  user: null,
                  agreement: userData ? userData["meta.agreement"] : false,
                  cooperate: userData ? userData["meta.cooperate"] : false,
                  loaded: true,
                  requested: true,
                  needRefresh: false,
                },
              });
            }
            setTimeout(() => {
              if (userData && userData["meta.web-token"]) {
                const webToken = localStorage.getItem("web-token");
                if (webToken !== userData["meta.web-token"]) {
                  if (isProd()) {
                    auth.signOut();
                    return;
                  }
                }
              }
            }, 0);
          },
          (err: any) => {
            console.error("Unable to load package detail");
            console.error(err);

            dispatch({
              type: SENTIMENT_ACTION.NS_USER,
              payload: {
                user: null,
                agreement: false,
                cooperate: false,
                loaded: true,
                requested: true,
                needRefresh: true,
              },
            });
          }
        );
    } catch (err) {
      console.error("Unable to load user detail");
      console.error(err);
      dispatch({
        type: SENTIMENT_ACTION.NS_USER,
        payload: {
          user: null,
          agreement: false,
          cooperate: false,
          loaded: true,
          requested: true,
          needRefresh: true,
        },
      });
    }
  };
}

export default function NsUser(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.NS_USER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const nsUserSelector = (state: any) => state?.NsUser;
