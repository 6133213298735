import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import { storage } from "../../../firebase";

const DATA_COUNT = 100;
const COLLECTION_NAME = "BRO_COMMENT";

export interface IComment {
  id: any,
  date: any;
  text: string;
  richText: string;
  img: string;
  url: string;
}
interface ICommentList {
  list: IComment[];
  requested: boolean;
  loaded: boolean;
}
const initialState: ICommentList = {
  list: [],
  requested: false,
  loaded: false,
};

export function loadComment(firebase: any) {

  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Comment.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.COMMENT,
      payload: { requested: true, loaded: false, list: [] },
    });
    firebase
      .firestore()
      .collection(COLLECTION_NAME)      
      .orderBy("date", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var data: IComment[] = [];

        querySnapshot.forEach(function (doc: any) {
          let item = doc.data();
          data.push({
            id: item.id,
            date: moment
            .unix(item.date.seconds),
            text: item.text,
            richText: item.richText,
            img: item.img,
            url: item.url,
          });

        });

        dispatch({
          type: SENTIMENT_ACTION.COMMENT,
          payload: {
            list: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Comment(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.COMMENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const commentSelector = (state: any) => state.Comment;
