import React, { useState } from "react";
import { Layout, Popover } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions";
import SearchBox from "../../components/SearchBox";
import UserInfo from "../../components/UserInfo";
import AppNotification from "../../components/AppNotification";
import MailNotification from "../../components/MailNotification";
import Auxiliary from "util/Auxiliary";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import { SettingOutlined } from "@ant-design/icons";
import LanguageMenu from "../../components/LanguageMenu/LanguageMenu";

const { Header } = Layout;

const Topbar = (props) => {
  const { locale, navStyle, themeType } = useSelector(
    ({ settings }) => settings
  );
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={() => dispatch(switchLanguage(language))}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };

  return (
    <div>
      {navStyle === NAV_STYLE_DRAWER ||
        ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
          width < TAB_SIZE) ? (
        <Header>
          <div className="gx-linebar gx-mr-3">
            <i
              className="gx-icon-btn icon icon-menu"
              onClick={() => {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              }}
            />
          </div>
          <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer mobile-top-icon">
            {themeType === THEME_TYPE_DARK ? (
              <img alt="logo2" height="20px" src={"/assets/images/logo.png"} />
            ) : (
              <img
                alt="logo1"
                height="20px"
                src={"/assets/images/logo-white.png"}
              />
            )}
          </Link>
          <div className="header-mobile-settings">
            <LanguageMenu />
            <div className="header-settings-icon">
              <SettingOutlined
                
                onClick={() => {
                  if (props.setShowsSettings) props.setShowsSettings(true);
                }}
                style={{ fontSize: 17 }}
              />
            </div>
          </div>
        </Header>
      ) : null}
    </div>
  );
};

export default Topbar;
