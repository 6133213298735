import { useEffect } from "react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { CookieConsentConfig } from "vanilla-cookieconsent";

const config: CookieConsentConfig = {
  language: {
    default: "tr",
    translations: {
      tr: {
        consentModal: {
          description:
            'İnternet sitemizde, içeriğin tarafınıza sağlanması, İnternet Sitemizin performansının optimize edilmesi ve ziyaretçi profilinin anlaşılması için gerekli olan çerezler kullanılmaktadır. İnternet Sitemiz üzerinde kullanılan çerezler hakkında detaylı bilgi almak için <a target="_blank" class="cc-link" href="https://sentimentalgo.com/gizlilik-politikasi/">Çerez Politikamızı</a> incelemenizi rica ederiz. Çerezlere dair tercihlerinizi Çerez Yönetim Paneli vasıtasıyla yönetmeniz mümkündür.',
          acceptAllBtn: "Hepsini Kabul Et",
          showPreferencesBtn: "Çerezleri Yönet",
        },
        preferencesModal: {
          title: "Çerez Politikaları",
          acceptAllBtn: "Hepsini Kabul Et",
          savePreferencesBtn: "Seçilenleri Kaydet",
          sections: [
            {
              description:
                'Sitemizden en iyi şekilde faydalanabilmeniz için, amaçlarla sınırlı ve gizliliğe uygun şekilde çerez konumlandırmaktayız. Tercihlerinizin hatırlanması ve sitedeki deneyiminizi iyileştirmek için <a target="_blank" href="https://sentimentalgo.com/gizlilik-politikasi/" class="cc-link">Çerez Politikamızı</a> kabul etmenizi öneririz.',
            },
            {
              title: "Gerekli",
              description:
                "Gerekli çerezler, web sitesinin temel işlevleri için çok önemlidir ve web sitesi bunlar olmadan amaçlandığı şekilde çalışmayacaktır.",
              linkedCategory: "necessary",
              cookieTable: {
                headers: {
                  name: "Çerez",
                  description: "Açıklama",
                  expiration: "Süre",
                },
                body: [
                  {
                    name: "accessToken",
                    description:
                      "Sitemize giriş yaptıktan sonra size ait olan oturum anahtarıdır. Oturumunuzun açılabilmesi için gereklidir.",
                    expiration: "1 Yıl",
                  },
                  {
                    name: "user",
                    description:
                      "Oturum açıldıktan sonra site içerisinde gerekli yerlerde kullanıcı bilgilerinizin gösterilmesi için kullanılmaktadır. Örneğin profil sayfasında isminizin, soyisminizin ve profil fotoğrafınızın görünmesini sağlamak gibi.",
                    expiration: "1 Yıl",
                  },
                  {
                    name: "cc_cookie",
                    description:
                      "Çerez tercihlerinizin tutulması için kullanılmaktadır.",
                    expiration: "6 Ay",
                  },
                ],
              },
            },
            {
              title: "Daha Detaylı Bilgi Almak İçin",
              description:
                'Çerez Politikalarımız hakkında daha fazla bilgi almak için <a class="cc-link" target="_blank" href="https://sentimentalgo.com/iletisim/">Bize Ulaşın</a>.',
            },
          ],
        },
      },
    },
  },
  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
    },
  },
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom right",
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: "box",
      position: "right",
      equalWeightButtons: true,
      flipButtons: false,
    },
  },
};

const CookieManager = () => {
  useEffect(() => {
    CookieConsent.run(config);
  }, []);

  return <></>;
};

export default CookieManager;
