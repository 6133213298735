import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 1000;
const COLLECTION_NAME = "SYMBOL_XU100_15";

interface IXUData {
  data: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  data: [],
  requested: false,
  loaded: false,
};

export function loadXu100P15(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.XU100P15.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.SYMBOL_XU100_15,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("date", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var xu100: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          let item = doc.data();
          xu100.push({
            dateTime: moment.unix(item.date.seconds).format("DD.MM.yyyy HH:mm"),
            date: moment.unix(item.date.seconds),
            open: item.open,
            high: item.high,
            low: item.low,
            close: item.close,
            aort: item.aort,
            vol: item.vol,
            size: item.size,
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.SYMBOL_XU100_15,
          payload: {
            data: xu100.sort((a: any, b: any) => { return a.date - b.date }),
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function XU100P15(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.SYMBOL_XU100_15:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const xu100P15Selector = (state: any) => state.XU100P15;
