import React, { useEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";

const CustomScrollbars = (props) => {
  const scrollbarsRef = useRef(null);

  useEffect(() => {
    scrollbarsRef.current.scrollToBottom();
  }, [props.new]);

  return (
    <Scrollbars
      {...props}
      ref={scrollbarsRef}
      autoHide
      renderTrackHorizontal={(props) => (
        <div {...props} style={{ display: "none" }} className="track-horizontal" />
      )}
    />
  );
};

export default CustomScrollbars;
