import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Modal } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  IPackagePrice,
  loadPrices,
  priceSelector,
} from "../../appRedux/reducers/payment/prices";
import { LoadingWidget } from "../../components/LoadingWidget/LoadingWidget";
import { firebaseApp } from "../../firebase";
import { CouponModal } from "./CouponModal";
import { usePayment } from "./PaymentContext";

export const NewPackage = (props: any) => {
  const dispatch = useDispatch();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState("");
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [couponCode, setCouponCode] = useState<any>(null);
  const [priceType, setPriceType] = useState<
    "weekly" | "monthly" | "three_monthly" | "six_monthly" | "yearly"
  >("monthly");
  useEffect(() => {
    dispatch(loadPrices(firebaseApp));
  }, []);

  const { details, setDetails } = usePayment();
  const priceData = useSelector(priceSelector);
  const router = useHistory();
  const getPriceAfterCode = (price: any, code: any) => {
    const packagePrice = price.prices[
      priceType as any
    ] as any as Number as number;
    if (!code) return packagePrice;
    if (!code.product) return packagePrice;
    if (!Array.isArray(code.product)) return packagePrice;
    if (!code.product.includes(price.packageId)) return packagePrice;

    switch (code.type) {
      case "constant":
        return packagePrice - code.amount;
      case "percentage":
        return Math.floor((packagePrice * (100 - code.amount)) / 100);
    }
    return packagePrice;
  };
  const getPriceAfterBalance = (price: any, details: any) => {
    const balance = details?.balance ?? 0;
    let packagePrice = getPriceAfterCode(price, couponCode);
    const result = (packagePrice - balance) as number;
    if (result < 0) return 0;
    return result;
  };

  const getSelectedPackageName = () => {
    return priceData?.data.find((p: any) => p.id === selectedPackageId)?.name;
  };
  const getSelectedPackagePriceAfterBalance = () => {
    const price = priceData?.data?.find((p: any) => p.id === selectedPackageId);
    if (!price) return 0;
    return getPriceAfterBalance(price, details);
  };
  const hasBalance = (details: any) => {
    const balance = details?.balance ?? 0;
    return balance > 0;
  };
  const isPayable = (price: any, details: any) => {
    const amount = getPriceAfterBalance(price, details);
    return amount >= 1;
  };
  const getPriceTypeText = () => {
    if (priceType === "monthly") return "1 aylık";
    if (priceType === "three_monthly") return "3 aylık";
    if (priceType === "six_monthly") return "6 aylık";
    if (priceType === "weekly") return "haftalık";
    return "";
  };

  const couponValidation = (priceType: any, couponCode: any) => {
    if (!couponCode) return true;
    if (couponCode?.prevent6Month !== true) return true;
    if (priceType === "six_monthly" && couponCode?.prevent6Month === true)
      return false;
    return true;
  };

  const getCouponWarning = (priceType: any, couponCode: any) => {
    if (!couponCode) return null;
    if (couponCode?.prevent6Month !== true) return null;
    if (priceType === "six_monthly" && couponCode?.prevent6Month === true) {
      if (couponCode?.prevent6MonthText) return couponCode?.prevent6MonthText;
    }
    return null;
  };

  const isSamePackageEarlyPurhcaseAttempt = (price: any, details: any) => {
    if (!price) return false;
    if (price.packageId !== details?.currentPackageId) return false;

    if (couponCode && couponCode.ignoreLimits) return false;

    if (details.settings && details.settings.LIMIT_TYPE === "no_limit") {
      return false;
    }
    if (details && details.currentPackageStartTime) {
      const start = moment(details.currentPackageStartTime, "DD.MM.YYYY");
      const end = moment(details.currentPackageEndTime, "DD.MM.YYYY");
      const duration = moment(end).diff(moment(start), "days");
      // allow purchase from monthly to 3 monthly

      if (
        details.settings &&
        details.settings.LIMIT_TYPE === "wait_percentage" &&
        !isNaN(details.settings.WAIT_PERCENTAGE)
      ) {
        let ignoreUpgrade = false;

        if (
          (priceType === "three_monthly" || priceType === "six_monthly") &&
          duration < 33
        )
          ignoreUpgrade = true;
        if (!ignoreUpgrade) {
          if (
            moment().diff(start, "days") <
            (duration * details.settings.WAIT_PERCENTAGE) / 100
          ) {
            return true;
          }
        }
      }
      if (
        details.settings &&
        details.settings.LIMIT_TYPE === "wait_day" &&
        !isNaN(details.settings.WAIT_DAY)
      ) {
        let ignoreUpgrade = false;
        if (
          (priceType === "three_monthly" || priceType === "six_monthly") &&
          duration < 33
        )
          ignoreUpgrade = true;
        if (!ignoreUpgrade) {
          if (moment(end).diff(moment(), "days") > details.settings.WAIT_DAY) {
            return true;
          }
        }
      }
    }
    return false;
  };

  if (
    !priceData ||
    !priceData.data ||
    priceData?.data?.length === 0 ||
    !priceData.loaded
  )
    return <LoadingWidget />;
  if (!details) return <LoadingWidget />;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 12,
        flexGrow: 1,
        justifyContent: "center",
      }}
    >
      <div className="price-type">
        <div className="desktop-coupon-code"></div>
        <div className="price-type-select">
          <div
            className={priceType === "weekly" ? "selected-price-type" : ""}
            onClick={() => setPriceType("weekly")}
          >
            Haftalık
          </div>
          <div
            className={priceType === "monthly" ? "selected-price-type" : ""}
            onClick={() => setPriceType("monthly")}
          >
            Aylık
          </div>
          <div
            className={
              priceType === "three_monthly" ? "selected-price-type" : ""
            }
            onClick={() => setPriceType("three_monthly")}
          >
            3 Aylık
          </div>
          <div
            style={{ position: "relative" }}
            className={priceType === "six_monthly" ? "selected-price-type" : ""}
            onClick={() => setPriceType("six_monthly")}
          >
            6 Aylık
            <div className="payment-down-arrow">
              <img
                className="payment-arrow"
                src="/assets/images/arrow.png"
                alt="arrow"
              />
              <img
                className="payment-white-arrow"
                src="/assets/images/arrow-white.png"
                alt="arrow"
              />
              <span>%20'ye varan indirim</span>
            </div>
          </div>
        </div>
        <div className="desktop-coupon-code">
          <div
            className="coupon-code-link"
            onClick={() => setShowCouponModal(true)}
          >
            Kupon Kodu Kullan{" "}
          </div>
          {couponCode && (
            <span style={{ color: "#0067ff", textDecoration: "none" }}>
              ({couponCode.code})
            </span>
          )}
        </div>
      </div>
      <div className="mobile-coupon-code">
        <div
          className="coupon-code-link"
          onClick={() => setShowCouponModal(true)}
        >
          Kupon Kodu Kullan{" "}
        </div>
        {couponCode && (
          <span style={{ color: "#0067ff", textDecoration: "none" }}>
            ({couponCode.code})
          </span>
        )}
      </div>
      {hasBalance(details) && (
        <div className="balance-warning">
          <div>
            <span>
              <InfoCircleOutlined />
            </span>
            <span>
              Aşağıda yer alan paket fiyatları, mevcut{" "}
              {details.currentPackageName} paketinizin kalan süresinden
              hesaplanan{" "}
              {details?.balance?.toLocaleString("tr-TR", {
                style: "decimal",
                maximumFractionDigits: 0,
              })}{" "}
              TL'lik bakiyenizin düşülmesi sonucu hesaplanmıştır.
            </span>
          </div>
        </div>
      )}
      {couponCode && (
        <div className="package-coupon">
          {" "}
          <span>
            <InfoCircleOutlined />
          </span>{" "}
          <div>
            Kupon Kodu: {couponCode.code} uygulandıktan sonraki fiyatlar
            gösterilmektedir.{" "}
            <span className="remove-coupon" onClick={() => setCouponCode(null)}>
              Kodu kaldır
            </span>
          </div>
        </div>
      )}
      {getCouponWarning(priceType, couponCode) ? (
        <div className="package-coupon">
          {" "}
          <span>
            <InfoCircleOutlined />
          </span>
          {getCouponWarning(priceType, couponCode)}
        </div>
      ) : null}

      <div className="price-card-container">
        {priceData?.data
          ?.filter((p: any) => p.active)
          .map((price: IPackagePrice, index: number) => {
            return (
              <Card
                hoverable
                className={
                  "package-card fadein-element fadein-element-delay-" + index
                }
              >
                {price.mostPopular && (
                  <div className="most-popular">Çok satan</div>
                )}
                <div className="package-title">{price.name}</div>
                {details?.balance > 0 && isPayable(price, details) && (
                  <div className="package-price-string">
                    ₺{" "}
                    {getPriceAfterBalance(price, details)?.toLocaleString(
                      "tr-TR",
                      {
                        style: "decimal",
                        maximumFractionDigits: 0,
                      }
                    )}
                  </div>
                )}
                {details?.balance > 0 && isPayable(price, details) && (
                  <div>
                    <div className="package-price-string-small">
                      <div>Paket fiyatı</div>
                      <div>
                        {" "}
                        ₺{" "}
                        {(
                          price.prices[priceType as any] as any as Number
                        ).toLocaleString("tr-TR", {
                          style: "decimal",
                          maximumFractionDigits: 0,
                        })}{" "}
                        /{" "}
                        {priceType === "weekly"
                          ? "haftalık"
                          : priceType === "monthly"
                          ? "aylık"
                          : priceType === "three_monthly"
                          ? "3 aylık"
                          : priceType === "six_monthly"
                          ? "6 aylık"
                          : priceType === "yearly"
                          ? "yıllık"
                          : ""}
                      </div>
                    </div>
                  </div>
                )}
                {(details?.balance <= 0 || !isPayable(price, details)) && (
                  <div>
                    <div className="package-price-string">
                      ₺{" "}
                      {getPriceAfterCode(price, couponCode).toLocaleString(
                        "tr-TR",
                        {
                          style: "decimal",
                          maximumFractionDigits: 0,
                        }
                      )}
                    </div>
                    <div className="package-price-type-text">
                      /{" "}
                      {priceType === "weekly"
                        ? "haftalık"
                        : priceType === "monthly"
                        ? "aylık"
                        : priceType === "three_monthly"
                        ? "3 aylık"
                        : priceType === "six_monthly"
                        ? "6 aylık"
                        : priceType === "yearly"
                        ? "yıllık"
                        : ""}
                    </div>
                  </div>
                )}
                <div
                  className={
                    "package-buy-button " +
                    (price.mostPopular ? "most-popular-button" : "")
                  }
                >
                  <button
                    disabled={
                      !couponValidation(priceType, couponCode) ||
                      !isPayable(price, details) ||
                      isSamePackageEarlyPurhcaseAttempt(price, details)
                    }
                    onClick={() => {
                      if (hasBalance(details)) {
                        setSelectedPackageId(price.id);
                        setTimeout(() => {
                          setShowUpgradeModal(true);
                        });
                      } else
                        router.push(
                          `/payment/customer?new-package-id=${
                            price.id
                          }&duration=${priceType}&code=${
                            couponCode?.code ?? ""
                          }`
                        );
                    }}
                  >
                    Satın Al
                  </button>
                </div>
                <div className="package-details-container">
                  {price.details?.map((detail) => (
                    <div>{detail}</div>
                  ))}
                </div>
              </Card>
            );
          })}
      </div>
      <Modal
        title="Sentiment Algo Ödeme Sistemi"
        open={showUpgradeModal}
        onOk={() => {
          router.push(
            `/payment/customer?new-package-id=${selectedPackageId}&duration=${priceType}&code=${
              couponCode?.code ?? ""
            }`
          );
        }}
        okText="Devam"
        cancelText="İptal"
        onCancel={() => {
          setShowUpgradeModal(false);
          setSelectedPackageId("");
        }}
      >
        <div className="upgrade-details">
          Bu satın alma işlemi ile mevcut <b>{details?.currentPackageName}</b>{" "}
          aboneliğiniz sona erecektir. Aboneliğinizden kalan sürenizin
          hesaplanması sonucu elde edilen{" "}
          <b>
            ₺{" "}
            {details?.balance?.toLocaleString("tr-TR", {
              style: "decimal",
              maximumFractionDigits: 0,
            })}{" "}
          </b>
          bakiye düşülerek{" "}
          <b>
            {getPriceTypeText()} {getSelectedPackageName()}
          </b>{" "}
          paketi ₺{" "}
          <b>
            {getSelectedPackagePriceAfterBalance()?.toLocaleString("tr-TR", {
              style: "decimal",
              maximumFractionDigits: 0,
            })}{" "}
          </b>
          ye satın almaktasınız.
        </div>
      </Modal>
      {showCouponModal && (
        <CouponModal
          onClose={() => setShowCouponModal(false)}
          selectCode={(code: string) => setCouponCode(code)}
        />
      )}
    </div>
  );
};
