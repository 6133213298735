import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import ImkbEndeksSenetler from "../../../util/ImkbEndeksSenetler";
import { string } from "prop-types";
import { isProd } from "../../../util/Env";
import klineInterval from "./params";
import { linesMapper } from "../mapper/mapper";

const DATA_COUNT = 30;
const COLLECTION_NAME = "NS_LINES_INDEXES";

interface IXUData {
  linesIndexes: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  linesIndexes: [],
  requested: false,
  loaded: false,
};

export function loadLinesIndexes(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.LinesIndexes.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.LINES_INDEXES,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}`)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .get().then((querySnapshot: any) => {

        var data: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          data.push(linesMapper(doc.data(), 0, "linesindices"));
        });

        if (isProd()) {
          const indexes: Record<string, boolean> = {
            "XU100": true,
            "XU050": true,
            "XU030": true,
            "XK100": true,
            "XYLDZ": true,
            "XKTUM": true
          };

          data = data.filter((x: any) => !indexes[x.Symbol])
        }

        data = data.sort((a: any, b: any) => { return b.Sentiment - a.Sentiment })
        data.forEach(function (data: any, i) {
          data.Order = i + 1;
        });

        dispatch({
          type: SENTIMENT_ACTION.LINES_INDEXES,
          payload: {
            linesIndexes: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function LinesIndexes(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.LINES_INDEXES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const linesIndexesSelector = (state: any) => state.LinesIndexes;
