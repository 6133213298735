import { useEffect } from "react";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import { useDispatch, useSelector } from "react-redux";
import {
  loadBulletins,
  bulletinsSelector,
} from "../../appRedux/reducers/sentiment/bulletins";
import {
  PERMISSIONS,
  hasPermission,
} from "../../appRedux/reducers/sentiment/permissions";
import { firebaseApp } from "../../firebase";
import { Avatar } from "antd";
import { Link } from "react-router-dom";
import { nsUserSelector } from "../../appRedux/reducers/sentiment/user";
import moment from "moment";

const BulletsPopover = () => {
  const { themeType } = useSelector(({ settings }) => settings);

  const dispatch = useDispatch();
  const nsUser = useSelector(nsUserSelector);

  useEffect(() => {
    dispatch(loadBulletins(firebaseApp));
  }, []);
  const bulletins = useSelector(bulletinsSelector);

  if (!bulletins) return <div>Yükleniyor...</div>;
  if (!hasPermission(nsUser, PERMISSIONS.BULLETIN_LIST)) {
    return (
      <div>Raporları Görüntüleyebilmek İçin Lütfen Hesabınızı Yükseltin</div>
    );
  }

  return (
    <Auxiliary>
      <div className="gx-popover-header">
        <h3 className="gx-mb-0">Raporlar</h3>
        <Link to="/bulletins">
          <h6>
            Tüm Raporları Gör{" "}
            <img
              src={
                themeType === "THEME_TYPE_DARK"
                  ? "/assets/images/click-white.png"
                  : "/assets/images/click-black.png"
              }
              alt="help-click"
              height="14px"
              width="14px"
            />
          </h6>
        </Link>
      </div>
      <CustomScrollbars
        style={{
          height: 280,
          width: 300,
          position: "relative",
          overflow: "hidden",
          margin: "0 -16px",
        }}
      >
        {!bulletins ? (
          <div>Yükleniyor</div>
        ) : (
          <ul className="gx-sub-popover">
            {bulletins?.data?.slice(0, 5).map((bulletin, index) => (
              <Link to={bulletin.url} target="_blank">
                <li className="gx-media" key={index}>
                  <div className="gx-user-thumb gx-mr-3">
                    <Avatar
                      className="gx-size-30"
                      alt="sentiment-algo"
                      src="/assets/images/mini-logo.png"
                    />
                  </div>
                  <div className="gx-media-body">
                    <div className="gx-flex-row gx-justify-content-between gx-align-items-center">
                      <h5 className="gx-text-capitalize gx-user-name gx-mb-0">
                        <span className="gx-link">
                          {moment(bulletin.dateTime).format("DD.MM.YYYY HH:mm")}
                        </span>
                      </h5>
                      <span className="gx-meta-date">
                        <small></small>
                      </span>
                    </div>
                    <p
                      className="gx-fs-sm "
                      style={{
                        color:
                          themeType === "THEME_TYPE_DARK" ? "white" : "black",
                      }}
                    >
                      {bulletin.name}
                    </p>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        )}
      </CustomScrollbars>
    </Auxiliary>
  );
};

export default BulletsPopover;
