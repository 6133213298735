import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 100;
const COLLECTION_NAME = "IND_XUSTPMTP";

interface IXUData {
  data: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  data: [],
  requested: false,
  loaded: false,
};

function calculateCross(px: number, cx: number, pStp: number, cStp: number, pMtp: number, cMtp: number): { cross: boolean, point: number | null, upOrDown: boolean } {
  let cross = false;
  let point: number | null = null;
  let upOrDown: boolean = false;

  const crossSTP_UP = px < pStp && cx >= cStp;
  const crossSTP_DOWN = px >= pStp && cx < cStp;
  const crossMTP_UP = px < pMtp && cx >= cMtp;
  const crossMTP_DOWN = px >= pMtp && cx < cMtp;

  const underSTP = cx < cStp;
  const underMTP = cx < cMtp;

  if (crossSTP_UP || crossSTP_DOWN || crossMTP_UP || crossMTP_DOWN) {
    cross = true;
    point = 0;
    point += underSTP ? -2 : 2;
    point += underMTP ? -1 : 1;
  }
  upOrDown = crossSTP_UP || crossMTP_UP;

  return { cross, point, upOrDown };
}

function processData(data: any[], stpField: string, mtpField: string) {
  for (let i = 1; i < data.length; i++) {
    const prev = data[i - 1];
    const current = data[i];

    const { cross, point, upOrDown } = calculateCross(
      prev[stpField],
      current[stpField],
      prev[`${stpField}STP`],
      current[`${stpField}STP`],
      prev[`${mtpField}MTP`],
      current[`${mtpField}MTP`]
    );

    data[i] = {
      ...current,
      [`cross${stpField.toUpperCase()}`]: cross,
      [`point${stpField.toUpperCase()}`]: point,
      upOrDown: upOrDown
    };
  }
  return data;
}

export function loadXuStpMtp(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.XuStpMtp.requested) return;

    dispatch({
      type: SENTIMENT_ACTION.XUSTPMTP,
      payload: { requested: true, loaded: false, data: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("date", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        let data: any[] = [];

        querySnapshot.forEach((doc: any) => {
          const docData = doc.data();
          data.push({
            xu100: docData.xu100,
            xu030: docData.xu030,
            xu030MTP: docData.xu030MTP,
            xu100MTP: docData.xu100MTP,
            xu030STP: docData.xu030STP,
            xu100STP: docData.xu100STP,
            date: moment(docData.date.toDate()).format("DD.MM.YY"),
          });
        });

        data.reverse();

        data = processData(data, "xu100", "xu100");
        data = processData(data, "xu030", "xu030");

        dispatch({
          type: SENTIMENT_ACTION.XUSTPMTP,
          payload: {
            data: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function XuStpMtp(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.XUSTPMTP:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export const xuStpMtpSelector = (state: any) => state.XuStpMtp;
