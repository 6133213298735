import { Button, Col, Divider, Row, Switch, Typography } from "antd";
import {
  UserOutlined,
  QuestionCircleOutlined,
  MoonOutlined,
  SunOutlined,
  LogoutOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import LanguageMenu from "../../components/LanguageMenu/LanguageMenu";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../authentication";
import { useDispatch, useSelector } from "react-redux";
import { setThemeType } from "../../appRedux/actions";

const { Text } = Typography;

interface IUserPopover {
  setShowsSettings?: any;
  closePopover?: any;
}

const UserPopover = (props: IUserPopover) => {
  const history = useHistory();
  const { userSignOut, authUser } = useAuth();

  const dispatch = useDispatch();

  const onLogoutClick = () => {
    userSignOut(() => {
      history.push("/");
    });
  };
  const { themeType } = useSelector(({ settings }: any) => settings);

  const getThemeIcon = () => {
    if (themeType === "THEME_TYPE_DARK") {
      return <SunOutlined style={{ fontSize: 22 }} />;
    } else {
      return <MoonOutlined style={{ fontSize: 22 }} />;
    }
  };

  const toggleTheme = () => {
    const newThemeType =
      themeType === "THEME_TYPE_DARK" ? "THEME_TYPE_LITE" : "THEME_TYPE_DARK";
    dispatch(setThemeType(newThemeType));
  };

  return (
    <Row justify="center" style={{ marginLeft: 5, width: 268 }}>
      <Col
        span={24}
        style={{ cursor: "pointer" }}
        className={
          themeType === "THEME_TYPE_DARK"
            ? "bp-menuitem-dark-hover"
            : "bp-menuitem-lite-hover"
        }
      >
        <Link
          to="/account"
          onClick={() => {
            props.closePopover?.();
          }}
        >
          <Row style={{ padding: "10px 0" }}>
            <Col span={4}>
              <UserOutlined
                style={{
                  fontSize: 18,
                  color: themeType === "THEME_TYPE_DARK" ? "white" : "black",
                }}
              />
            </Col>
            <Col span={20}>
              <Text
                strong
                style={{
                  color: themeType === "THEME_TYPE_DARK" ? "white" : "",
                }}
              >
                Hesabım
              </Text>
            </Col>
          </Row>
        </Link>
      </Col>
      <Col
        span={24}
        className={
          themeType === "THEME_TYPE_DARK"
            ? "bp-menuitem-dark-hover"
            : "bp-menuitem-lite-hover"
        }
        onClick={() => {
          props.setShowsSettings(true);
          props.closePopover?.();
        }}
        style={{ cursor: "pointer" }}
      >
        <Row style={{ padding: "10px 0" }}>
          <Col span={4}>
            <QuestionCircleOutlined style={{ fontSize: 18 }} />
          </Col>
          <Col span={20}>
            <Text
              strong
              style={{
                color: themeType === "THEME_TYPE_DARK" ? "white" : "",
              }}
            >
              Yardım
            </Text>
          </Col>
        </Row>
      </Col>

      <Col
        span={24}
        style={{ cursor: "pointer" }}
        className={
          themeType === "THEME_TYPE_DARK"
            ? "bp-menuitem-dark-hover"
            : "bp-menuitem-lite-hover"
        }
      >
        <Link
          to="/chat"
          onClick={() => {
            props.closePopover?.();
          }}
        >
          <Row style={{ padding: "10px 0" }}>
            <Col span={4}>
              <MessageOutlined
                style={{
                  fontSize: 18,
                  color:
                    themeType === "THEME_TYPE_DARK" ? "#52C41A" : "#128c7e",
                }}
                className="bp-tada-animation-slow"
              />
            </Col>
            <Col span={20}>
              <Text
                strong
                style={{
                  color:
                    themeType === "THEME_TYPE_DARK" ? "#52C41A" : "#128c7e",
                }}
              >
                Canlı Destek
              </Text>
            </Col>
          </Row>
        </Link>
      </Col>

      <Col
        span={24}
        style={{ cursor: "pointer" }}
        className={
          themeType === "THEME_TYPE_DARK"
            ? "bp-menuitem-dark-hover"
            : "bp-menuitem-lite-hover"
        }
      >
        <LanguageMenu />
      </Col>
      <Divider style={{ margin: 0 }} />
      <Col
        span={24}
        onClick={() => {
          toggleTheme();
        }}
        style={{ cursor: "pointer", paddingTop: 6 }}
        className={
          themeType === "THEME_TYPE_DARK"
            ? "bp-menuitem-dark-hover"
            : "bp-menuitem-lite-hover"
        }
      >
        <Row style={{ padding: "10px 0" }}>
          <Col span={4}>{getThemeIcon()}</Col>
          <Col span={12}>
            <Text
              strong
              style={{
                color: themeType === "THEME_TYPE_DARK" ? "white" : "",
              }}
            >
              Tema
            </Text>
          </Col>
          <Col span={8}>
            <Switch
              size="small"
              checked={themeType === "THEME_TYPE_DARK"}
              onChange={toggleTheme}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ padding: "4px 10px 10px 10px" }}>
        <Button
          block
          type="primary"
          danger
          icon={<LogoutOutlined style={{ fontSize: 18 }} />}
          onClick={onLogoutClick}
          style={{ height: 40, borderRadius: 6 }}
        >
          Çıkış Yap
        </Button>
      </Col>
    </Row>
  );
};

export default UserPopover;
