import moment from "moment";
import 'moment/locale/tr';

export function getColor(data: string) {
  if (!data) return "royalblue";
  switch (data) {
    case "default":
      return "royalblue";
    case "red":
      return "#c12a2a";
    case "blue":
      return "#0b7d17";
    case "yellow":
      return "#d28a00";
    case "orange":
      return "#8319e0";
  }
}

export function getDateStr(date: string) {
  const messageDate = moment(date, "YYYY-MM-DD HH:mm:ss");
  const today = messageDate.isSame(new Date(), "day");
  if (today) return messageDate.format("Bugün, HH:mm");
  const sameMonth = messageDate.isSame(new Date(), "month");
  if (sameMonth) {
    messageDate.locale("tr");

    return messageDate.format("DD MMMM, HH:mm");
  }
  return messageDate.format("YYYY-MM-DD HH:mm");
}

export function getShortDate(date: string) {
  moment.locale('tr');
  return moment(date).format('LLL');
}